import React, { useState } from "react";
import './chat.css';

const Chat = () => {
    const [inputValue, setInputValue] = useState("");
    const [conversation, setConversation] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // 设置loading状态为true
        try {
            const response = await fetch("https://trojan-usa.dylanpeng.one/chat/chatgpt/chat", {
                method: "POST",
                mode: 'cors',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ content: inputValue }),
            });
            const { data } = await response.json();
            const message = data.message
            setConversation([...conversation, { type: "question", message: inputValue }, { type: "answer", message }]);
            setInputValue("");
        } catch (error) {
            console.log(error);
        }
        setLoading(false); // 请求返回后，将loading状态设置为false
    };

    function handleKeyDown(e) {
        if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    }

    return (
        <div className="chat-box">
            <div className="conversation">
            <h1>Chat API</h1>
            {conversation.map((item, index) => (
                <div key={index}>
                    <span style={{ fontWeight: "bold" }}>{item.type === "question" ? "我: " : "ChatGPT: "}</span>
                    <span>{item.message}</span>
                    <p/>
                </div>
            ))}
            <div ref={(el) => (el ? el.scrollIntoView() : null)} />
            </div>
            <form className="input-form" onSubmit={handleSubmit}>
                <textarea value={inputValue} className="input-field" onChange={handleInputChange} onKeyDown={handleKeyDown} placeholder="请输入您的问题"/>
                <button type="submit" disabled={loading}>{loading ? "正在思考中..." : "发送"}</button>
            </form>
            {loading && <div className="chat-box-overlay"></div>}
        </div>
    );
};

export default Chat;